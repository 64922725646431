import React, { useState } from 'react';
import styles from './ContactSection.module.css';

export const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const ACTION_URL = 'https:docs.google.com/forms/d/e/1FAIpQLSdNdDXKKPq4B9iVSt0NZXzZjLmCCTeXoYd0lsUK-eWYKt0tyA/formResponse';
    const MESSAGE_ID = 'entry.839337160';
    const EMAIL_ID = 'entry.1045781291';
    const NAME_ID = 'entry.2005620554';

    const formResponseUrl = `${ACTION_URL}?${NAME_ID}=${encodeURIComponent(formData.name)}&${EMAIL_ID}=${encodeURIComponent(formData.email)}&${MESSAGE_ID}=${encodeURIComponent(formData.message)}`;

    const iframe = document.getElementById('contact-form');
    iframe.src = formResponseUrl;


    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <div className={styles.contactSectionContainer}>
      <h2 className={styles.contactTitle} id="contact">Contact Me</h2>
      <form className={styles.contactForm} onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          className={styles.inputField}
          onChange={handleInputChange}
          value={formData.name}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          className={styles.inputField}
          onChange={handleInputChange}
          value={formData.email}
          required
        />
        <textarea
          name="message"
          placeholder="Your Message"
          className={styles.textArea}
          rows="5"
          onChange={handleInputChange}
          value={formData.message}
          required
        ></textarea>
        <button type="submit" className={styles.submitButton}>
          Send Message
        </button>
      </form>
      {/* Hidden iframe for Google Forms submission */}
      <iframe  id="contact-form" style={{ display: 'none' }} src="https://docs.google.com/forms/d/e/1FAIpQLSdNdDXKKPq4B9iVSt0NZXzZjLmCCTeXoYd0lsUK-eWYKt0tyA/viewform?embedded=true" width="640" height="689" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  );
};
