import './App.module.css';
import { Experience } from './Components/Experience/Experience';
import { Navbar } from './Components/Header/Navbar';
import Hero from './Components/Hero/Hero';
import { Skills } from './Components/Skills/Skills';
import { ContactSection } from './Components/ContactSection/ContactSection';
import { Footer } from './Components/Footer/Footer';
import { AboutMe } from './Components/AboutMe/AboutMe';
import { HashRouter as Router } from 'react-router-dom';

function App() {
  return (
    <Router>
    <Navbar/>
    <Hero/>
    <div id="skills">
    <Skills/>
    </div>
    <Experience id="experience"/>
    <AboutMe id="about"/>
    <ContactSection id="contact"/>
    <Footer/>
    </Router>
  );
}

export default App;
